import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: "Home",
    icon: "bx-home-circle",
    link: "/dashboard",
    role: "admin",
    permission: "view_dashboard_main_page",
  },
  {
    id: 2,
    label: "Users",
    icon: "bx bx-group",
    role: "admin",
    permission: "view_clients",
    subItems: [
      {
        id: 21,
        label: "Users",
        link: "/users",
        parentId: 2,
        role: "admin",
      },
      {
        id: 22,
        label: "Drivers",
        link: "/drivers",
        parentId: 2,
        role: "admin",
      },
    ],
  },

  {
    id: 3,
    label: "Pages",
    icon: "bx bx-book-open",
    link: "/pages",
    role: "admin",
    permission: "view_pages",
  },
  {
    id: 4,
    label: "Geolocations",
    icon: "bx-world",
    role: "admin",
    permission: "view_regions",
    subItems: [
      {
        id: 41,
        label: "Regions",
        link: "/regions/regions",
        parentId: 4,
        role: "admin",
        permission: "view_regions",
      },
      {
        id: 42,
        label: "Cities",
        link: "/regions/cities",
        parentId: 4,
        role: "admin",
        permission: "view_regions",
      },
      {
        id: 43,
        label: "Distrects",
        link: "/regions/distrects",
        parentId: 4,
        role: "admin",
        permission: "view_regions",
      },
      {
        id: 44,
        label: "Zones",
        link: "/regions/zones",
        parentId: 4,
        role: "admin",
        permission: "view_regions",
      },
    ],
  },

  {
    id: 5,
    label: "Categories",
    icon: "bx bx-grid-alt",
    link: "/categories",
    role: "admin",
    permission: "view_categories",
  },

  {
    id: 16,
    label: "Busy Orders Calendar",
    icon: "bx bx-calendar-event",
    link: "/busy-days",
    role: "admin",
    permission: "busy_orders_days",
  },
  {
    id: 17,
    label: "Order Distribution Calendar",
    icon: "bx bx-calendar-event",
    link: "/drivers-calendar",
    role: "admin",
    permission: "busy_orders_days",
  },
  {
    id: 6,
    label: "Home Page Ads",
    icon: "bx bx-images",
    link: "/sliders",
    role: "admin",
    permission: "view_sliders",
  },
  {
    id: 7,
    label: "Products",
    icon: "bx bxl-dropbox",
    link: "/products",
    role: "admin",
    permission: "view_products",
  },
  {
    id: 15,
    label: "Recycling Machines",
    icon: "bx bx-store",
    link: "/machines",
    role: "admin",
    permission: "view_machines",
  },

  {
    id: 250,
    label: "Machine Report",
    icon: "bx bx-store",
    link: "/machine-report",
    role: "admin",
    permission: "view_machine_reports",
  },
  {
    id: 8,
    label: "Vouchers Management",
    link: "/vouchers",
    icon: "bx bxs-offer",
    role: "admin",
    permission: "view_vouchers",
  },
  {
    id: 9,
    label: "Orders",
    link: "/orders",
    icon: "bx bx-food-menu",
    role: "admin",
    permission: "view_orders",
  },
  {
    id: 17,
    label: "Points Systems",
    link: "/points",
    icon: "bx bx-gift",
    role: "admin",
    permission: "view_points_system",
  },

  {
    id: 16,
    label: "Orders Cancellation Reasons",
    icon: "bx bx-align-right",
    role: "admin",
    permission: "view_cancellation_reasons",

    subItems: [
      {
        id: 161,
        label: "Admin Reasons",
        link: "/cancellation-reasons/admin",
        parentId: 16,
        role: "admin",
      },
      {
        id: 162,
        label: "User Reasons",
        link: "/cancellation-reasons/user",
        parentId: 16,
        role: "admin",
      },
    ],
  },

  {
    id: 10,
    label: "Roles",
    link: "/roles",
    icon: "bx bx-lock-alt",
    role: "admin",
    permission: "view_privileges",
  },
  {
    id: 11,
    icon: "bx bx-sitemap",
    label: "Management",
    link: "/management",
    role: "admin",
    permission: "view_users",
  },
  {
    id: 16,
    label: "Socials",
    icon: "bx bx-share-alt",
    link: "/socials",
    role: "admin",
    permission: "view_socials",
  },
  {
    id: 20,
    label: "Reports",
    icon: "bx bxs-report",
    role: "admin",
    permission: "view_reports",
    subItems: [
      {
        id: 201,
        label: "Redeem Points Transactions",
        link: "/reports/transactions",
        parentId: 20,
        role: "admin",
      },
    ],
  },

  {
    id: 12,
    label: "Content Management",
    icon: "bx bxs-notepad",
    role: "admin",
    permission: "view_blogs",
    subItems: [
      {
        id: 121,
        label: "Blogs",
        link: "/blogs",
        parentId: 12,
        role: "admin",
      },
      {
        id: 122,
        label: "Core Values",
        link: "/content-management/core-values",
        parentId: 12,
        role: "admin",
      },
      {
        id: 123,
        label: "Olar Services",
        link: "/content-management/services",
        parentId: 12,
        role: "admin",
      },
      {
        id: 124,
        label: "Olar Materials",
        link: "/content-management/materials",
        parentId: 12,
        role: "admin",
      },
      {
        id: 125,
        label: "Olar Materials Stats",
        link: "/content-management/materials-stats",
        parentId: 12,
        role: "admin",
      },
      {
        id: 126,
        label: "Olar Target Materials",
        link: "/content-management/target-matrials",
        parentId: 12,
        role: "admin",
      },
      {
        id: 127,
        label: "What makes Olar special?",
        link: "/content-management/olar-specials",
        parentId: 12,
        role: "admin",
      },
      {
        id: 128,
        label: "Olar Partners",
        link: "/content-management/olar-partners",
        parentId: 12,
        role: "admin",
      },
      {
        id: 129,
        label: "Users Testimonials",
        link: "/content-management/olar-testimonials",
        parentId: 12,
        role: "admin",
      },
      {
        id: 1210,
        label: "Olar Main Goals",
        link: "/content-management/main-goals",
        parentId: 12,
        role: "admin",
      },
      {
        id: 1211,
        label: "Olar Employee Benefits",
        link: "/content-management/employee-benefits",
        parentId: 12,
        role: "admin",
      },

      {
        id: 1212,
        label: "Olar Features Page",
        link: "/content-management/special-sections",
        parentId: 12,
        role: "admin",
      },
      {
        id: 1213,
        label: "What Olar Do Page",
        role: "admin",
        parentId: 12,

        subItems: [
          {
            id: 12131,
            label: "Intro Section",
            link: "/content-management/olar-do/intro-sec",
            parentId: 1213,
            role: "admin",
          },
          {
            id: 12132,
            label: "What Olar Do List",
            link: "/content-management/olar-do/revolutionize-recycling-list",
            parentId: 1213,
            role: "admin",
          },
          {
            id: 12133,
            label: "Olar App List",
            link: "/content-management/olar-do/olar-app-list",
            parentId: 1213,
            role: "admin",
          },
          {
            id: 12134,
            label: "Olar Services Sections",
            link: "/content-management/olar-do/services-sections-list",
            parentId: 1213,
            role: "admin",
          },
        ],
      },
    ],
  },

  {
    id: 13,
    label: "Support",
    icon: "bx-support",
    role: "admin",
    permission: "view_support_topics_templates",
    subItems: [
      {
        id: 131,
        label: "Support Topics",
        link: "/support/topics",
        parentId: 13,
      },
      {
        id: 132,
        label: "Support Messages",
        link: "/support/list",
        parentId: 13,
      },
    ],
  },
  {
    id: 14,
    label: "Notifications",
    icon: "bx bx-bell bx-tada",
    link: "/notification",
    role: "admin",
    permission: "view_notifications",
  },
  {
    id: 15,
    label: "Settings",
    icon: "bx bx-cog",
    link: "/settings",
    role: "admin",
    permission: "view_settings",
  },
];
